import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout/layout';
import { navigate } from "@reach/router";
import shareicon from '../assets/images/ic-share-arrow.svg';
import CF from '../components/commonfunctions';
import closeicon from '../assets/images/ic-close-black.svg';
import Pagination from 'rc-pagination';
import DS1 from '../assets/images/DS1.png';
import ReactHtmlParser from "react-html-parser";
import { formatDate } from '../utlis';
import ScrollAnimation from 'react-animate-on-scroll';
import Search from '../components/search';
import ScaleLoader from "react-spinners/ScaleLoader";
import { S3_URL } from "gatsby-env-variables";

export const query = graphql`
{
  WordPress {
    menu(id: "dGVybToxOQ==") {
      name
      menuItems {
        nodes {
          label
          cssClasses
          menu_data {
            externalLink
            sectionId
            svg {
              sourceUrl
            }
          }
        }
      }
    }
  }
  }
  `
let pageSize = 4;
const ShowAll = ({ data }) => {
    const [isSticky, setSticky] = useState(false);
    const [isOpen, setIsopen] = useState(false);
    const [scrollOffset, setOffset] = useState(0);
    const [viewAll, setViewAll] = useState(false);
    const [disclamier, setDisclamier] = useState(true);
    const [list, listData] = useState([]);
    const [pageListAllData, setPageListAllData] = useState([]);
    const [page, newPage] = useState(1);
    const [total, newTotal] = useState();
    const handleScroll = (e) => {
        var headerH = document.querySelector('header') ? document.querySelector('header').offsetHeight : '';
        setSticky(window.scrollY > headerH ? true : false);
    };

    const settingDisclamier = () => {
        setDisclamier(false);
        localStorage.setItem('disclamier', false);
    }
    useEffect(() => {
        let token = CF.getItem('token');
        /*  if (!token) navigate('/login'); */

        window.addEventListener('scroll', handleScroll);
        // pagination();
        getListDataApi();
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    const handleCategory = (e, label) => {
        if (label == "Search") {

            setIsopen(!isOpen)
        }
        else if (label == "Resources") {
            navigate('/resources')
        }
        else if (label == 'Subscribe') {
            e.preventDefault();
            e.stopPropagation();
            const scrollSec = document.querySelector(e.target.hash);
            const headerOffset = document.getElementsByClassName("category-menu-outer") && document.getElementsByClassName("category-menu-outer")[0].clientHeight;
            const offsetH = (scrollSec.offsetTop - (!isSticky ? 108 + headerOffset : 108));
            setOffset(offsetH)
            window.scrollTo({
                behavior: "smooth",
                top: offsetH
            });
        }
        else if (label === 'Show All') navigate('/showall')
        else if (label === "Category 1") navigate('/category1')
        else if (label === "Category 2") navigate('/category2')
        else if (label === "Category 3") navigate('/category3')
    }


    const getListDataApi = async () => {

        let data = await fetch(S3_URL + `/ds.json`)
        .then(response=>{
          return response.json()
        })
        let dataArray = [...data]
        setPageListAllData(data);
        let slicedArray = dataArray.slice(0, pageSize);
        listData(slicedArray)
        newPage(1);
        newTotal(Number(data.length))
      }

    const pagination = async (e) => {

        let dataArray = [...pageListAllData]
        let slicedArray = dataArray.slice(pageSize*(e-1), pageSize*e);
        listData(slicedArray)
        newPage(e ? e : 1);
      }


    let menuArr = data.WordPress.menu && data.WordPress.menu.menuItems && data.WordPress.menu.menuItems.nodes;
    return (
        <React.Fragment>
            {list && list.length && Array.isArray(list) ?
                <Layout uma="inner-page" headerColor="header-DS" title={"DS"} seoTitle={'Data Stories'} seoDes={"x-musix data stories page"}>
                    {/* {pageTitle ? <h1> {pageTitle} </h1> : ""} */}
                    <div className={`category-menu-outer ds-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
                        <div className="container">
                            {/********************* Category Menu Start *********************/}
                            <nav className="category-menu">
                                <ul>{menuArr && menuArr.length ? menuArr.map((menu, key) => {
                                    return (<li className="seven-category" key={key} onClick={(e) => handleCategory(e, menu.label)} >
                                        <a id="responsive-menu-trigger" href={menu && menu.label === 'Subscribe' ? '#conatctus' : menu.label === "Search"  && "#"}>
                                            <span className="category-img">
                                                <img src={menu && menu.menu_data && menu.menu_data.svg && menu.menu_data.svg.sourceUrl} alt={menu.label} />
                                            </span>
                                            <span className="category-name">{menu.label}</span>
                                        </a>
                                    </li>)
                                }) : null}</ul>
                            </nav>
                            {/********************* Category Menu End *********************/}
                        </div>
                    </div>
                    {/******************** Search *******************/}
                    <Search />
                    {/*********************************************/}
                    <section className="common-banner ds-banner">
                        <div className="container">
                            <div className="common-banner-content">
                                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                    <h1>All Shows</h1>
                                </ScrollAnimation>
                            </div>
                            {/********************* Data Stories Listing Start *********************/}
                            <div className="data-story-listing">
                                {/********************* Notification start *********************/}
                                {(disclamier && CF.getItem('disclamier') !== 'false') ? <div className="notify">
                                    <p>This content is intended for internal use only and shall not be distributed outside of Universal Music Group.</p>
                                    <a className="close-notify" href="javascript:;" onClick={settingDisclamier}><img src={closeicon} alt="Close" /></a>
                                </div> : ""}
                                {/********************* Notification end *********************/}
                                <div className="row">
                                    {/********************* Data story block start *********************/}
                                    {list && list.length ? list.map((post, key) => {
                                        let desc = post.content && post.content.rendered ? ReactHtmlParser(post.content.rendered) : '';
                                        let image = post && post._embedded && post._embedded['wp:featuredmedia'][0].source_url;
                                        if (key < 2 || viewAll) {
                                            return (<div className="col-md-6" key={key} >
                                                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                                                    <div className="data-story-card" style={{ backgroundImage: image ? `url(${image})` : `url(${DS1})` }}>
                                                        <a className="share-icon" href="mailto:?Subject=X music&amp;Body=">
                                                            <img src={shareicon} alt="Share" />
                                                        </a>
                                                        <div className="title-date">
                                                            <span className="date">{formatDate(post.date, "MM/DD/YYYY")}</span>
                                                            <h6>{post && post.title && post.title.rendered}</h6>
                                                        </div>
                                                        <div className="data-story-card-overaly">
                                                            <a href="javascript:;" onClick={() => navigate(`/ds-details/?id=${post.slug}`)} >
                                                                <div className="data-story-card-overaly-content">
                                                                    <span className="date">{formatDate(post.date)}</span>
                                                                    <h6>{post && post.title && post.title.rendered}</h6>
                                                                    {desc ? desc : ""}
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </ScrollAnimation>
                                            </div>)
                                        }
                                    }) : null}
                                </div>
                                {list && list.length > 2 && !viewAll ? <div className="view-all-sec">
                                    <a href="javascript:;" className="view-all-btn" onClick={() => setViewAll(true)}>{viewAll === false ? 'VIEW ALL' : ''}</a>
                                </div> : ""}
                            </div>

                            {viewAll ? <div className="row">
                                <div className="col-md-12">
                                    <Pagination showLessItems pageSize={pageSize} current={page} total={total} onChange={(e) => pagination(e)} locale={true} />
                                </div>
                            </div> : ""}
                            {/********************* Data Stories Listing End *********************/}
                        </div>
                    </section>
                </Layout> : <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div>}
        </React.Fragment >
    );
};

export default ShowAll;
